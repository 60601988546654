import { defineStore } from "pinia";
import {useStorage} from "@vueuse/core";
export const useLayoutStore = defineStore("layout", () => {
  const landingVersion = useStorage("landing-version", "0");
  const isLandingVersion = (versions: Array<string>) => {
    return versions.some(item => landingVersion.value === item)
  }
  return {
    landingVersion,
    isLandingVersion
  };
});